import { qs, getType, getToken, getAccessToken, getStatusCode } from '@services/globalHelpers'
import { PasswordCreation } from './password-creation'
import { PasswordForgot } from './password-forgot'
import TYPES from './_services/types'
import Login from './login'
import Login2FA from './2FA'

class IndexRouter {
  constructor(type, token) {
    this._type = type ? type.toLowerCase() : null;
    this._token = token;
    this._scope = qs(`body[class='login']`);
    this._login = new Login();

    this.show();
  }

  show() {
    if(!this._type) {
      this._login.start();
      this._login.logIn({code:getStatusCode()});
      return;
    }

    this.router();
  }

  router() {
    if(this._type === TYPES.PAGE_PASSWORD_FORGOT) {
      PasswordForgot(this._scope).start();
    }

    if(this._type === TYPES.PAGE_PASSWORD_CREATION) {
      PasswordCreation(this._scope).start();
    }

    if(this._type === TYPES.TOKEN_HUGME && getAccessToken()) {
      this._login.logIn({ token:getAccessToken(), code:getStatusCode() });
    }
  }
}

new IndexRouter(getType(), getToken())

