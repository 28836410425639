// Global
import * as globalFactory from '@services/globalFactories'
import * as Helpers from '@services/globalHelpers';

// Local
import SELECTOR from '../_services/selectors';
import Template from './template.html';
import * as Model from '../../hug-seguranca/2FA/_services/model';
import * as Api from './_services/api';
import { showNotification, modalType, resetNotifications } from './_services/util';
import { MSG_CODE_INVALID } from './_services/model';
import StatusCode from '../_services/status-code';
import TYPES from '../_services/types';

export default class Login2FA {
  constructor(scope) {
    if (!scope) {
      throw new Error('Missing input argument');
    }

    this._scope = scope;
    this._$input = null;
    this._$btnCheckCode = null;
    this._$btnResendCode = null;
    this.accessToken = null;
    this._content = Helpers.qs(SELECTOR.CLASS_LOGIN_CONTENT, scope);

    // para o teste de unidade
    this.page = null;
  }

  static get Template() {
    return Template;
  }

  start(accessToken) {
    if (!accessToken) {
      throw new TypeError('No access token')
    }

    this._content.innerHTML = this.constructor.Template;
    this.accessToken = accessToken;
    this._addClassOnScope('page-auth');

    setTimeout(()=> {
      this._content.removeAttribute('style');
    },300)

    // para o teste de unidade
    this.page = Helpers.qs(`div`, this._scope);

    this._bindEvents();
  }

  _bindEvents() {
    this._$input = Helpers.qs('#code');
    this._$btnCheckCode = Helpers.qs('#btn-check-code');
    this._$btnResendCode = Helpers.qs('#btn-resend-code');

    this._$input.focus();
    this._$input.oninput = this._copyPasteInput;

    Helpers.$on(this._$btnCheckCode, 'click', ({currentTarget}) => this.checkCode(currentTarget));
    Helpers.$on(this._$btnResendCode, 'click', ({currentTarget}) => this.resendCode(currentTarget));

    Helpers.$on(this._$input, 'keyup', ({currentTarget}) => {
      this._$btnCheckCode.disabled = currentTarget.value.length >= 1 ? false : true;
    });

    Helpers.$on(this._$input, 'keydown', (event) => {
      if(event.which === 13 || event.keyCode === 13) {
        event.preventDefault();
      }
    });

    Helpers.$on(this._$input, 'keypress',(event) => globalFactory.inputNotAllowWhiteSpace(event));
  }

  async checkCode(element) {
    try {
      if(!this._$input.value) throw new Error('No code')

      Helpers.enableLoading(element);

      const { data } = await Api.auth({ code:this._$input.value, token:Helpers.getAccessToken() || this.accessToken }); 

      resetNotifications();

      StatusCode({ code:201, data:{ token:data.hash, type:TYPES.TOKEN_JWT }});
    } catch (error) {
      if(error.hasOwnProperty('response')) {
        if(error.response.status === 401) {
          showNotification({ message:MSG_CODE_INVALID, type: 'danger' })
          return;
        }

        if(error.response.status === 403) {
          modalType('sessionExpired').open();
          return;
        }
      }

      showNotification({ message: error, type: 'danger'});
    } finally {
      Helpers.disableLoading();
    }
  }

  async resendCode(element) {
    try {
      Helpers.enableLoading(element);

      await Api.sendCodeToEmail(this.accessToken);

      showNotification({message: Model.Labels.MSG_CODE_RESEND, type: 'warning'});
    } catch (error) {

      if(error.response.status === 401) {
        modalType('invalidToken').open();
        return;
      }

      if(error.response.status === 403) {
        modalType('sessionExpired').open();
        return;
      }

      showNotification({ message: error, type: 'danger'});
    } finally {
      Helpers.disableLoading();
      this._cleanUpInput();
      this._disableButtonCheckCode();
    }
  }

  _addClassOnScope(nome) {
    this._scope.classList.add(nome);
  }

  _cleanUpInput() {
    this._$input.value = '';
  }

  _disableButtonCheckCode() {
    this._$btnCheckCode.disabled = true;
  }

  _enableButtonCheckCode() {
    this._$btnCheckCode.disabled = false;
  }

  _copyPasteInput(e) {
    const $btnCheckCode = Helpers.qs('#btn-check-code');
    const $input = Helpers.qs('#code');

    $btnCheckCode.disabled = e.currentTarget.value.length >= 1 ? false : true;

    if(/^\s/.test($input.value)) {
      $input.value = '';
      $btnCheckCode.disabled = true;
    }
  }
}
